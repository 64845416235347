import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledButton from 'components/MuiComponents/RoundedButton';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { media, mediaMUI } from 'theme/styled-theme';
import { sendEvent } from 'utils/gtag';
import LazyBackgroundImage from 'components/Img/LazyBackgroundImage';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  display: flex;
  color: ${props => (props.reverse ? 'white' : '#343434')};
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  transition: color 0.3s ease-in;

  ${media.forPhoneOnly`
    display: block;
    height: inherit;
  `}

  ${media.forTabletPortraitOnly`
    flex-direction: row;
    max-width: 100%;
    height: 341px;
  `}
`;

const CountryName = styled.h3`
  font-weight: 700;
  font-size: 3rem;
  margin: 0.5rem 0;
  text-transform: capitalize;

  ${media.forPhoneOnly`
    font-size: 2.25rem;
  `}
`;

const Description = styled.span`
  font-size: 1rem;
  margin: 0.5rem 0 1.75rem;

  ${media.forPhoneOnly`
    font-size: 0.875rem;
    margin: 0.5rem 0 1.25rem;
  `}
`;

const photoStyle = css`
  position: relative;
  flex: 1 0 52%;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  ${media.forPhoneOnly`
    flex: 1 0 50%;
    display: block;
    min-height: 184px;
  `}

  ${media.forTabletPortraitOnly`
    flex: 1 0 46%;
  `}
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 46%;
  padding: 2rem;
  background-color: ${props => props.backgroundColor};

  ${media.forPhoneOnly`
    text-align: center;
    padding: 1.5rem 1rem;
    flex: 1 0 50%;
  `}
`;

const DesktopActionButtonWrap = styled.div`
  display: flex;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const HomepageCountryCard = props => {
  const {
    country, callToActionText, backgroundColor, reverse
  } = props;
  const { history } = useReactRouter();
  const { name, description, photo } = country;
  const matchePhone = useMediaQuery(mediaMUI.forPhoneOnly);

  const onClickHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      sendEvent({
        action: 'Click Country Card Action Button',
        category: 'navigation',
        label: name
      });
      history.push(`/country/${name}`);
    },
    [matchePhone]
  );

  const buttonStyles = useMemo(
    () => ({
      root: {
        color: reverse ? 'white!important' : '#343434',
        borderColor: reverse ? 'white!important' : '#343434',
        '&:active, &:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: '#595959',
        },
      }
    }),
    [reverse]
  );

  return (
    <Link
      to={`/country/${name}`}
      onClick={() => {
        sendEvent({
          action: 'Click Country Card',
          category: 'navigation',
          label: name
        });
      }}
    >
      <Root>
        <LazyBackgroundImage src={photo} overwritestyles={photoStyle} />
        <DescriptionBox backgroundColor={backgroundColor}>
          <CountryName>{name}</CountryName>
          <Description>{description}</Description>
          <DesktopActionButtonWrap>
            <StyledButton
              variant="outlined"
              overwritestyles={buttonStyles}
              onClick={onClickHandler}
            >
              {callToActionText}
            </StyledButton>
          </DesktopActionButtonWrap>
        </DescriptionBox>
      </Root>
    </Link>
  );
};

HomepageCountryCard.propTypes = {
  country: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    photo: PropTypes.string
  }),
  backgroundColor: PropTypes.string,
  callToActionText: PropTypes.string,
  reverse: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

HomepageCountryCard.defaultProps = {
  country: {},
  backgroundColor: '#F9DB8B',
  callToActionText: 'See more things to do',
  reverse: false
};

export default HomepageCountryCard;
